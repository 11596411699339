import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';
import AddressForm from '../../components/Form/Address';
import { Form, Select, Input, Button, message } from 'antd';

import {
  ProvinceName,
  DistrictName,
  SubDistrictName,
  LocationName,
  SubLocationName,
} from '../../tools';

import provinces from '../../data/address/provinces.json';
import districts from '../../data/address/districts.json';
import subDistricts from '../../data/address/subDistricts.json';
import zipcodes from '../../data/address/zipcodes.json';
import { list as locationList } from '../../data/locationList';

import { LiffLogin, SavaDataLiff } from '../../services/api';

const { Option } = Select;
const { TextArea } = Input;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [optionProvinces] = useState(provinces);
  const [optionDistricts, setOptionDistricts] = useState(districts);
  const [optionSubDistricts, setOptionSubDistricts] = useState(subDistricts);

  const [userId, setUserId] = useState('');
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [subDistrict, setSubDistrict] = useState();
  const [zipCode, setZipCode] = useState('');
  const [area, setArea] = useState('');
  const [location, setLocation] = useState(0);
  const [locationDetail, setLocationDetail] = useState('');
  const [subLocation, setSubLocation] = useState(0);
  const [subLocationDetail, setSubLocationDetail] = useState('');

  useEffect(() => {
    const onLogin = async () => {
      const res = await LiffLogin(liff, '1654379233-BpJK0JNK');
      setUserId(res.userId);
    };
    onLogin();
  }, [liff]);

  const onChangeAddress = (value, key) => {
    switch (key) {
      case 'province':
        setProvince(value);
        setOptionDistricts(
          districts.filter((item) => item.PROVINCE_ID === +value)
        );
        setDistrict('');
        break;
      case 'district':
        setDistrict(value);
        setOptionSubDistricts(
          subDistricts.filter((item) => item.DISTRICT_ID === +value)
        );
        setSubDistrict('');
        break;
      case 'sub_district':
        setSubDistrict(value);
        setZipCode(
          zipcodes.filter(
            (item) => item.SUB_DISTRICT_ID === value.toString()
          )[0].ZIPCODE
        );
        break;
      case 'area':
        setArea(value);
        break;

      default:
        break;
    }
  };

  const onSubmit = async () => {
    let payload = {
      userId: userId,
      data: {
        province: ProvinceName(province) || '',
        district: DistrictName(district) || '',
        subDistrict: SubDistrictName(subDistrict) || '',
        zipCode: zipCode || '',
        area: area || '',
        location: LocationName(location) || '',
        locationDetail:
          LocationName(location) === 'อื่นๆ' ? locationDetail : '',
        subLocation: SubLocationName(location, subLocation) || '',
        subLocationDetail:
          SubLocationName(location, subLocation) === 'อื่นๆ'
            ? subLocationDetail
            : '',
      },
    };

    console.log('on submit', payload);
    const res = await SavaDataLiff(payload);
    if (res.status === 200) {
      message.success('บันทึก');
      liffClose();
    } else {
      message.error(res.message);
    }
  };

  const liffClose = () => {
    liff.init({ liffId: '1654379233-BpJK0JNK' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'กรอกข้อมูลเสร็จแล้ว',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  return (
    <Layout>
      <div className='location-report'>
        <Header title='สถานที่พบอาการไม่พึงประสงค์' />
        <Form layout='vertical'>
          <Form.Item label='ประเภทสถานที่พบ'>
            <Select onChange={(e) => setLocation(e)} value={location}>
              {locationList.map((item, index) => {
                return (
                  <Option key={index} value={item.key}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {locationList[location].option.length > 0 && (
            <Form.Item label='รายละเอียดสถานที่พบ'>
              <Select onChange={(e) => setSubLocation(e)}>
                {locationList[location].option.map((item, index) => {
                  return (
                    <Option key={index} value={item.key}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {(location === locationList.length - 1 ||
            subLocation === locationList[location].option.length - 1) && (
            <Form.Item label='รายละเอียดเพิ่มเติม'>
              <TextArea
                placeholder='รายละเอียดเพิ่มเติม'
                value={
                  location === locationList.length - 1
                    ? locationDetail
                    : subLocationDetail
                }
                onChange={(e) =>
                  location === locationList.length - 1
                    ? setLocationDetail(e.target.value)
                    : setSubLocationDetail(e.target.value)
                }
              />
            </Form.Item>
          )}
          <AddressForm
            options={{
              optionProvinces,
              optionSubDistricts,
              optionDistricts,
            }}
            province={province}
            subdistrict={subDistrict}
            district={district}
            zipCode={zipCode}
            area={area}
            onChange={onChangeAddress}
          />
          <Form.Item>
            <Button
              type='primary '
              block
              onClick={() => onSubmit()}
              disabled={!province || !district || !subDistrict}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
