import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';
import AddressForm from '../../components/Form/Address';
import { Form, Select, Input, Button, Radio, message } from 'antd';

import { ProvinceName, DistrictName, SubDistrictName } from '../../tools';

import provinces from '../../data/address/provinces.json';
import districts from '../../data/address/districts.json';
import subDistricts from '../../data/address/subDistricts.json';
import zipcodes from '../../data/address/zipcodes.json';

import { list as locationList } from '../../data/locationList';
import { list as nationlityList } from '../../data/nationlityList';

import { LiffLogin, SavaDataLiff } from '../../services/api';

const { Option } = Select;
const { TextArea } = Input;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [optionProvinces] = useState(provinces);
  const [optionDistricts, setOptionDistricts] = useState(districts);
  const [optionSubDistricts, setOptionSubDistricts] = useState(subDistricts);

  const [userId, setUserId] = useState('');
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [subdistrict, setSubDistrict] = useState();
  const [zipCode, setZipCode] = useState('');
  const [area, setArea] = useState('');
  const [location, setLocation] = useState(0);
  const [locationDetail, setLocationDetail] = useState('');

  const [nationality, setNationality] = useState(0);
  const [ethnicity, setEthnicity] = useState(nationlityList[0]);

  useEffect(() => {
    const onLogin = async () => {
      const res = await LiffLogin(liff, '1654379233-1pm24mw2');
      setUserId(res.userId);
    };
    onLogin();
  }, [liff]);

  const onChangeAddress = (value, key) => {
    switch (key) {
      case 'province':
        setProvince(value);
        setOptionDistricts(
          districts.filter((item) => item.PROVINCE_ID === +value)
        );
        setDistrict('');
        break;
      case 'district':
        setDistrict(value);
        setOptionSubDistricts(
          subDistricts.filter((item) => item.DISTRICT_ID === +value)
        );
        setSubDistrict('');
        break;
      case 'sub_district':
        setSubDistrict(value);
        setZipCode(
          zipcodes.filter(
            (item) => item.SUB_DISTRICT_ID === value.toString()
          )[0].ZIPCODE
        );
        break;
      case 'area':
        setArea(value);
        break;

      default:
        break;
    }
  };

  const onSubmit = async () => {
    let payload = {
      userId: userId,
      data: {
        nationality: nationality === 0 ? 'ชาวไทย' : 'ชาวต่างชาติ',
        ethnicity: nationality === 1 ? ethnicity : '',
        address:
          nationality === 0
            ? {
                province: ProvinceName(province),
                district: DistrictName(district),
                subDistrict: SubDistrictName(subdistrict),
                zipCode: zipCode,
                area: area,
              }
            : false,
      },
    };

    console.log('on submit', payload);
    const res = await SavaDataLiff(payload);
    if (res.status === 200) {
      message.success('บันทึก');
      liffClose();
    } else {
      message.error(res.message);
    }
  };

  const liffClose = () => {
    liff.init({ liffId: '1654379233-1pm24mw2' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'กรอกข้อมูลเสร็จแล้ว',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  const btnCheckDisable = () => {
    let check = false;
    if (nationality === 0) {
      check = province && district && subdistrict ? false : true;
    } else {
      check = ethnicity ? false : true;
    }
    return check;
  };

  return (
    <Layout>
      <div className='location-report'>
        <Header title='ที่อยู่ปัจจุบัน' />
        <Form layout='vertical'>
          <Form.Item>
            <Radio.Group
              value={nationality}
              onChange={(e) => setNationality(e.target.value)}
            >
              <Radio value={0}>ชาวไทย</Radio>
              <Radio value={1}>ชาวต่างชาติ</Radio>
            </Radio.Group>
          </Form.Item>

          {nationality === 0 ? (
            <React.Fragment>
              {locationList[location].option.length > 0 && (
                <Form.Item label='รายละเอียดสถานที่พบ'>
                  <Select onChange={(e) => setLocation(e)}>
                    {locationList[location].option.map((item, index) => {
                      return (
                        <Option key={index} value={item.key}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              )}
              {location === locationList.length - 1 && (
                <Form.Item label='รายละเอียดเพิ่มเติม'>
                  <TextArea
                    placeholder='รายละเอียดเพิ่มเติม'
                    value={locationDetail}
                    onChange={(e) => setLocationDetail(e.target.value)}
                  />
                </Form.Item>
              )}
              <AddressForm
                options={{
                  optionProvinces,
                  optionSubDistricts,
                  optionDistricts,
                }}
                province={province}
                subdistrict={subdistrict}
                district={district}
                zipCode={zipCode}
                area={area}
                onChange={onChangeAddress}
              />
            </React.Fragment>
          ) : (
            <Form.Item label='ชาวต่างชาติ'>
              <Select onChange={(e) => setEthnicity(e)} value={ethnicity}>
                {nationlityList.map((item, index) => {
                  return (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item>
            <Button
              type='primary '
              block
              onClick={() => onSubmit()}
              disabled={btnCheckDisable()}
            >
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
