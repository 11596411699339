import React from 'react';

import { Form, Input, Select } from 'antd';

const { TextArea } = Input;
const { Option } = Select;

const Address = ({
  options,
  province,
  subdistrict,
  district,
  zipCode,
  area,
  onChange,
}) => {
  return (
    <React.Fragment>
      <Form.Item label='จังหวัด'>
        <Select
          showSearch
          optionFilterProp='children'
          onSelect={(e) => onChange(e, 'province')}
          value={province}
        >
          {options.optionProvinces.map((item, index) => (
            <Option key={index} value={item.PROVINCE_ID}>
              {item.PROVINCE_NAME}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='อำเภอ'>
        <Select
          disabled={!province}
          showSearch
          optionFilterProp='children'
          onSelect={(e) => onChange(e, 'district')}
          value={district}
        >
          {options.optionDistricts.map((item, index) => (
            <Option key={index} value={item.DISTRICT_ID}>
              {item.DISTRICT_NAME}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='ตำบล'>
        <Select
          disabled={!district}
          showSearch
          optionFilterProp='children'
          onSelect={(e) => onChange(e, 'sub_district')}
          value={subdistrict}
        >
          {options.optionSubDistricts.map((item, index) => (
            <Option key={index} value={item.SUB_DISTRICT_ID}>
              {item.SUB_DISTRICT_NAME}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <div className='form-item'>
        <span>รหัสไปรษณีย์</span>
        <Input disabled={!zipCode} value={zipCode} />
      </div>
      <Form.Item label='รายละเอียดที่อยู่'>
        <TextArea
          placeholder='โปรดระบุชื่อ ที่อยู่ หรือ สถานที่สำคัญที่ใกล้เคียงสถานที่พบ เช่น ร้านใจดี เลขที่ 13 ใกล้ธนาคารกรุงไทยสาขาโตรุ้ง ตลาดสด เทศบาลวารินชาราบ'
          value={area}
          onChange={(e) => onChange(e.target.value, 'area')}
        />
      </Form.Item>
    </React.Fragment>
  );
};

export default Address;
