import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';

import { Form, Input, Select, Button, message } from 'antd';
import {
  list as productModelList,
  FindProductName,
  FindPreparationName,
  FindUseName,
} from '../../data/productModelList.js';

import { LiffLogin, SavaDataLiff, GetDataLiff } from '../../services/api';

const { TextArea } = Input;
const { Option } = Select;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [userId, setUserId] = useState('');
  const [model, setModel] = useState(0);
  const [product, setProduct] = useState([0]);
  const [productDetail, setProductText] = useState('');
  const [preparation, setPreparation] = useState(0);
  const [preparationDetail, setPreparationText] = useState('');
  const [use, setUse] = useState(0);
  const [useDetail, setUseText] = useState('');

  useEffect(() => {
    const onLogin = async () => {
      const res = await LiffLogin(liff, '1654379233-Zrbg5bqg');
      setUserId(res.userId);
      const resp = await GetDataLiff({
        userId: res.userId,
      });
      if (resp.status === 200) {
        const key = productModelList.filter(
          (item) => item.name === resp.data
        )[0]?.key;
        setModel(+key);
      } else {
        message.warning(resp.message);
      }
    };
    onLogin();
  }, [liff]);

  const onSubmit = async () => {
    let payload = {
      userId: userId,
      data:
        model !== 9
          ? {
              product:
                model === 2
                  ? product.map((item) => FindProductName(model, item))
                  : FindProductName(model, product),
              productDetail: checkFreeText() ? productDetail : '',
              preparation:
                model === 2 ? FindPreparationName(model, preparation) : '',
              preparationDetail: preparationDetail,
              use: FindUseName(model, use),
              useDetail: productModelList[model].use[use]?.free_text
                ? useDetail
                : '',
            }
          : {
              product: checkFreeText() ? productDetail : '',
              use: productModelList[model].use[use]?.free_text ? useDetail : '',
            },
    };
    const res = await SavaDataLiff(payload);
    if (res.status === 200) {
      message.success('บันทึก');
      liffClose();
    } else {
      message.error(res.message);
    }
  };

  const liffClose = () => {
    liff.init({ liffId: '1654379233-Zrbg5bqg' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'กรอกข้อมูลเสร็จแล้ว',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  const checkFreeText = () => {
    if (model === 2) {
      return product.includes(productModelList[model].products.length - 1);
    } else {
      return productModelList[model].products[product]?.free_text;
    }
  };

  return (
    <Layout>
      <div className='product-model'>
        <Header title='ข้อมูลการใช้ผลิตภัณฑ์' />
        <Form layout='vertical'>
          <Form.Item
            label={`รูปแบบผลิตภัณฑ์ ${
              model === 2 ? '(สามารถเพิ่มได้มากกว่าหนึ่ง)' : ''
            }`}
          >
            {/* {model !== 9 && ( */}
            <Select
              onChange={(e) => (model === 2 ? setProduct(e) : setProduct(e))}
              mode={model === 2 && 'multiple'}
            >
              {productModelList[model].products.map((item, index) => {
                return (
                  <Option key={index} value={item.key}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
            {/* )} */}
            {checkFreeText() && (
              <TextArea
                className='mt-10'
                placeholder='อธิบายรายละเอียด'
                value={productDetail}
                onChange={(e) => setProductText(e.target.value)}
              />
            )}
          </Form.Item>
          {typeof productModelList[model].preparation !== 'undefined' && (
            <Form.Item label='วิธีการเตรียม'>
              <Select onSelect={(e) => setPreparation(+e)}>
                {productModelList[model].preparation.map((item, index) => {
                  return (
                    <Option key={index} value={item.key}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
              {typeof productModelList[model].preparation !== 'undefined' &&
                productModelList[model].preparation[preparation]?.free_text && (
                  <TextArea
                    className='mt-10'
                    placeholder='อธิบายรายละเอียด'
                    value={preparationDetail}
                    onChange={(e) => setPreparationText(e.target.value)}
                  />
                )}
            </Form.Item>
          )}
          <Form.Item
            label={model !== 9 ? 'วิธีการใช้' : 'ใช้ผลิตภัณฑ์นี้เพื่ออะไร'}
          >
            {/* {model !== 9 && ( */}
            <Select
              onSelect={(e) => {
                setUse(e);
              }}
            >
              {productModelList[model].use.map((item, index) => {
                return (
                  <Option key={index} value={item.key}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
            {productModelList[model].use[use]?.free_text && (
              <TextArea
                className='mt-10'
                placeholder='อธิบายรายละเอียด'
                value={useDetail}
                onChange={(e) => setUseText(e.target.value)}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type='primary' block onClick={onSubmit}>
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
