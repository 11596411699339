export const list = [
  {
    key: 0,
    name: 'ยาแผนปัจจุบัน',
    products: [
      {
        key: 0,
        name: 'ยาแคปซูล',
      },
      {
        key: 1,
        name: 'ยาเม็ด',
      },
      {
        key: 2,
        name: 'ยาน้ำ',
      },
      {
        key: 3,
        name: 'ยาทา',
      },
      {
        key: 4,
        name: 'ยาฉีด',
      },
      {
        key: 5,
        name: 'ยาเหน็บ',
      },
      {
        key: 6,
        name: 'ยาหยอด',
      },
      {
        key: 7,
        name: 'ยาพ่น',
      },
      {
        key: 8,
        name: 'ยาผง',
      },
      {
        key: 9,
        name: 'แผ่นแปะ',
      },
      {
        key: 10,
        name: 'อื่น',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'ทา/ฟอก/ป้ายผิว/ป้ายนอกร่างกาย' },
      { key: 2, name: 'อม' },
      { key: 3, name: 'ฉีด' },
      { key: 4, name: 'สูด/พ่น' },
      { key: 5, name: 'บ้วนปาก/ป้ายปาก' },
      { key: 6, name: 'สระ/ฟอกศีรษะ' },
      { key: 7, name: 'หยอด/ป้ายตา' },
      { key: 8, name: 'หยอดหู' },
      { key: 9, name: 'สอด/เหน็บก้น' },
      { key: 10, name: 'สอด/เหน็บช่องคลอด' },
      { key: 11, name: 'ฝังในร่างกาย' },
      { key: 12, name: 'แปะผิว' },
      { key: 13, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 1,
    name: 'ยาแผนโบราณ/ยาที่พัฒนาจากสมุนไพร',
    products: [
      {
        key: 0,
        name: 'ยาเม็ด',
      },
      {
        key: 1,
        name: 'ยาแคปซูล',
      },
      {
        key: 2,
        name: 'ยาน้ำ',
      },
      {
        key: 3,
        name: 'ยาทา',
      },
      {
        key: 4,
        name: 'ยาหยอด',
      },
      {
        key: 5,
        name: 'ยาพ่น',
      },
      {
        key: 6,
        name: 'ยาผง',
      },
      {
        key: 7,
        name: 'แผ่นแปะ',
      },
      {
        key: 8,
        name: 'ลูกกลอน',
      },
      {
        key: 9,
        name: 'อื่น',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'ทา/ฟอก/ป้ายผิว' },
      { key: 2, name: 'อม' },
      { key: 3, name: 'สูด/พ่น' },
      { key: 4, name: 'บ้วนปาก/ป้ายปาก' },
      { key: 5, name: 'สระ/ฟอกศีรษะ' },
      { key: 6, name: 'หยอด/ป้ายตา' },
      { key: 7, name: 'หยอดหู' },
      { key: 8, name: 'สอด/เหน็บก้น' },
      { key: 9, name: 'สอด/เหน็บช่องคลอด' },
      { key: 10, name: 'ฝังในร่างกาย' },
      { key: 11, name: 'แปะผิว' },
      { key: 12, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 2,
    name: 'สมุนไพร',
    products: [
      {
        key: 0,
        name: 'ใบ',
      },
      {
        key: 1,
        name: 'ราก',
      },
      {
        key: 2,
        name: 'ลำต้น',
      },
      {
        key: 3,
        name: 'ดอก',
      },
      {
        key: 4,
        name: 'ผล',
      },
      {
        key: 5,
        name: 'เมล็ด',
      },
      {
        key: 6,
        name: 'ลำต้นเหนือดิน',
      },
      {
        key: 7,
        name: 'ใช้ทั้งหมด',
      },
      {
        key: 8,
        name: 'อื่นๆ',
        free_text: true,
      },
    ],
    preparation: [
      { key: 0, name: 'กินสด' },
      { key: 1, name: 'ต้ม/เคี่ยว' },
      { key: 2, name: 'ชง' },
      { key: 3, name: 'ดองเหล้า' },
      { key: 4, name: 'ปรุงเป็นอาหาร' },
      { key: 5, name: 'อื่น', free_text: true },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'อม' },
      { key: 2, name: 'บ้วนปาก/ป้ายปาก' },
      { key: 3, name: 'ทา/ฟอก/ป้ายนอกร่างกาย' },
      { key: 4, name: 'หยอด/ป้ายตา' },
      { key: 5, name: 'หยอดหู' },
      { key: 6, name: 'สอด/เหน็บก้น' },
      { key: 7, name: 'สอด/เหน็บช่องคลอด' },
      { key: 8, name: 'สูด/พ่น' },
      { key: 9, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 3,
    name: 'วัตถุออกฤทธิ์/วัตถุเสพติด',
    products: [
      {
        key: 0,
        name: 'ยาเม็ด',
      },
      {
        key: 1,
        name: 'ยาแคปซูล',
      },
      {
        key: 2,
        name: 'ยาน้ำ',
      },
      {
        key: 3,
        name: 'ยาฉีด',
      },
      {
        key: 4,
        name: 'ยาเหน็บ',
      },
      {
        key: 5,
        name: 'ยาหยอด',
      },
      {
        key: 6,
        name: 'ยาพ่น',
      },
      {
        key: 7,
        name: 'ยาผง',
      },
      {
        key: 8,
        name: 'ยาทา',
      },
      {
        key: 9,
        name: 'แผ่นแปะ',
      },
      {
        key: 10,
        name: 'อื่น',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'ทา/ฟอก/ป้ายผิว/ป้ายนอกร่างกาย' },
      { key: 2, name: 'อม' },
      { key: 3, name: 'ฉีด' },
      { key: 4, name: 'สูด/พ่น' },
      { key: 5, name: 'บ้วนปาก/ป้ายปาก' },
      { key: 6, name: 'สระ/ฟอกศีรษะ' },
      { key: 7, name: 'หยอด/ป้ายตา' },
      { key: 8, name: 'หยอดหู' },
      { key: 9, name: 'สอด/เหน็บก้น' },
      { key: 10, name: 'สอด/เหน็บช่องคลอด' },
      { key: 11, name: 'ฝังในร่างกาย' },
      { key: 12, name: 'แปะผิว' },
      { key: 13, name: 'หยอดใต้ลิ้น' },
      { key: 14, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 4,
    name: 'ยาชุด',
    products: [
      {
        key: 0,
        name: 'ยาชุดเจริญอาหาร',
      },
      {
        key: 1,
        name: 'ยาชุดแก้ปวดเมื่อย',
      },
      {
        key: 2,
        name: 'ยาชุดลดไข้',
      },
      {
        key: 3,
        name: 'ยาชุดสเตียรอยด์',
      },
      {
        key: 4,
        name: 'ยาชุดลดน้ำหนัก',
      },
      {
        key: 5,
        name: 'ยาชุดรักษาฝ้ากระ',
      },
      {
        key: 6,
        name: 'ชุดหน้าขาวใส (ยาทา)',
      },
      {
        key: 7,
        name: 'ชุดฝ้ากระ (ยาทา)',
      },
      {
        key: 8,
        name: 'ยาชุดโรคเรื้อรัง',
      },
      {
        key: 9,
        name: 'ยาชุดช่วยให้นอนหลับ',
      },
      {
        key: 10,
        name: 'ยาชุดภูมิแพ้ หอบ หืด',
      },
      {
        key: 11,
        name: 'ยาชุดแก้ผิดสำแดง',
      },
      {
        key: 12,
        name: 'อื่นๆ',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'ทา/ฟอก/ป้ายนอกร่างกาย' },
      { key: 2, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 5,
    name: 'เครื่องสำอาง',
    products: [
      {
        key: 0,
        name: 'ทำความสะอาดใบหน้า',
      },
      {
        key: 1,
        name: 'ทำความสะอาดผิวหนัง',
      },
      {
        key: 2,
        name: 'เครื่องสำอางในช่องปาก',
      },
      {
        key: 3,
        name: 'ตกแต่งสีสันบนใบหน้า',
      },
      {
        key: 4,
        name: 'น้ำหอม/ดับกลิ่นกาย',
      },
      {
        key: 5,
        name: 'เครื่องสำอางบำรุงผิว/กันแดด',
      },
      {
        key: 6,
        name: 'เครื่องสำอางสำหรับสิวฝ้า',
      },
      {
        key: 7,
        name: 'เครื่องสำอางสำหรับผมและขน',
      },
      {
        key: 8,
        name: 'ผลิตภัณฑ์กำจัดขน',
      },
      {
        key: 9,
        name: 'แป้งฝุ่นโรยตัว',
      },
      {
        key: 10,
        name: 'อื่นๆ',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'ทา/ฟอก/ป้ายผิว' },
      { key: 1, name: 'บ้วนปาก/ป้ายปาก' },
      { key: 2, name: 'สระ/ฟอกศีรษะ' },
      { key: 3, name: 'แปะผิว' },
      { key: 4, name: 'ป้ายนอกร่างกาย' },
      { key: 5, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 6,
    name: 'ผลิตภัณฑ์เสริมอาหาร',
    products: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์นมและใช้แทนนม',
      },
      {
        key: 1,
        name: ' อาหารพร้อมบริโภคในภาชนะปิด',
      },
      {
        key: 2,
        name: 'เครื่องดื่มพร้อมบริโภค',
      },
      {
        key: 3,
        name: 'เครื่องดื่มชนิดผง',
      },
      {
        key: 4,
        name: 'บรรจุเม็ด/แคปซูล',
      },
      {
        key: 5,
        name: 'เจล/เจลลี่',
      },
      {
        key: 6,
        name: 'ลูกอม/ช๊อกโกแลต',
      },
      {
        key: 7,
        name: 'อื่นๆ',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'อม' },
      { key: 2, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 7,
    name: 'อาหาร',
    products: [
      {
        key: 0,
        name: 'อาหารสด',
      },
      {
        key: 1,
        name: ' อาหารแห้ง',
      },
      {
        key: 2,
        name: 'เครื่องปรุงรส',
      },
      {
        key: 3,
        name: 'อาหารปรุงสำเร็จ',
      },
      {
        key: 4,
        name: 'ผลิตภัณฑ์นมและใช้แทนนม',
      },
      {
        key: 5,
        name: 'อาหารกึ่งสำเร็จรูป',
      },
      {
        key: 6,
        name: 'อาหารพร้อมบริโภคในภาชนะปิด',
      },
      {
        key: 7,
        name: 'เครื่องดื่มพร้อมบริโภค',
      },
      {
        key: 8,
        name: 'เครื่องดื่มชนิดผง',
      },
      {
        key: 9,
        name: 'บรรจุเม็ด/แคปซูล',
      },
      {
        key: 10,
        name: 'ลูกอม/ช๊อกโกแลต',
      },
      {
        key: 11,
        name: 'น้ำมันพืช/ไขมันพืช',
      },
      {
        key: 12,
        name: 'อื่นๆ',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'กิน' },
      { key: 1, name: 'อม' },
      { key: 2, name: 'ทอด/ปรุงอาหาร' },
      { key: 3, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 8,
    name: 'วัตถุอันตราย/สารเคมี',
    products: [
      {
        key: 0,
        name: 'ชนิดของเหลว',
      },
      {
        key: 1,
        name: ' ชนิดของแข็ง',
      },
      {
        key: 2,
        name: 'แก๊ส',
      },
    ],
    use: [
      { key: 0, name: 'ฆ่าเชื่อแบคทีเรีย' },
      {
        key: 1,
        name: 'ทำความสะอาดพื้น / ฝาผนัง / เครื่องสุขภัณฑ์และวัสดุอื่นๆ',
      },
      {
        key: 2,
        name: 'ระงับ / ป้องกัน / ควบคุม / ไล่ / กำจัดแมลงและสัตว์อื่น',
      },
      { key: 3, name: 'แก้ไขการอุดตันของท่อ / ทางระบายสิ่งปฎิกูล' },
      { key: 4, name: 'ผลิตภัณฑ์ซักผ้าขาว / ซักแห้งผ้าหรือสิ่งทออื่นๆ' },
      { key: 5, name: 'ลบคำผิด / สารละลายที่ใช้เจือจางผลิตภัณฑ์ลบคำผิด' },
      {
        key: 6,
        name:
          'กาวที่มีสารสำคัญเป็นสารกลุ่มอัลคิลไซยาโนอะคริเลต (Alkyl cyanoacrylates)',
      },
      {
        key: 7,
        name: 'ใช้แล้วล้างออก',
      },
      { key: 8, name: 'อื่น', free_text: true },
    ],
  },
  {
    key: 9,
    name: 'เครื่องมือแพทย์',
    products: [
      {
        key: 0,
        name: ' รถเอกซ์เรเคลื่อนที่',
      },
      {
        key: 1,
        name: 'อื่น',
        free_text: true,
      },
    ],
    use: [
      { key: 0, name: 'ตรวจวิเคราะห์ เอกซเรย์ทางการแพทย์' },
      { key: 1, name: 'อื่น', free_text: true },
    ],
  },
];

export const FindProductName = (model, product) => {
  return list[model].products[product].name;
};

export const FindPreparationName = (model, preparation) => {
  return list[model]?.preparation[preparation].name;
};

export const FindUseName = (model, use) => {
  return list[model].use[use].name;
};
