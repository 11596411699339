import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';

import { Form, Input, Checkbox, Radio, Select, Button, message } from 'antd';

import { list as issuesList } from '../../data/issuesList.js';
import { list as testKitList } from '../../data/testKit.js';

import { LiffLogin, SavaDataLiff, GetDataLiff } from '../../services/api';

const { TextArea } = Input;
const { Option } = Select;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';

  const [userId, setUserId] = useState('');
  const [issues, setIssues] = useState(0);
  const [value, setValue] = useState([]);
  const [isTestKit, setIsTestKit] = useState(false);
  const [result, setResult] = useState(false);
  const [detail, setDetail] = useState([]);
  const [productType, setProductType] = useState(0);
  const [testKitType, setTestKitType] = useState(0);

  useEffect(() => {
    setValue(issuesList[issues].options.map(() => false));
    setDetail(issuesList[issues].options.map(() => ''));
  }, [issues]);

  useEffect(() => {
    const onLogin = async () => {
      const res = await LiffLogin(liff, '1654379233-wkv9QvP9');
      setUserId(res.userId);
      const resp = await GetDataLiff({ userId: res.userId });
      if (resp.status === 200) {
        const key = issuesList.filter((item) => item.name === resp.data)[0]
          ?.key;
        setIssues(+key);
      }
    };
    onLogin();
  }, [liff]);

  const onSubmit = async () => {
    let payload = {
      userId: userId,
      data: {
        issues: value.map((item, index) => {
          if (item) {
            return issuesList[issues].options[index].name;
          } else {
            return '';
          }
        }),
        detail: detail,
        testkit: isTestKit
          ? {
              result: result ? 'negative' : 'positive',
              product: testKitList.filter(
                (item) => item.key === +productType
              )[0].name,
              type: testKitList[+productType].options.filter(
                (item) => item.key === +testKitType
              )[0].name,
            }
          : {},
      },
    };
    console.log('on submit', payload);
    const res = await SavaDataLiff(payload);
    if (res.status === 200) {
      message.success('บันทึก');
      liffClose();
    } else {
      message.error(res.message);
    }
  };

  const liffClose = () => {
    liff.init({ liffId: '1654379233-wkv9QvP9' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'กรอกข้อมูลเสร็จแล้ว',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  return (
    <Layout>
      <div className='issues-report'>
        <Header title='เลือกประเด็นที่ต้องสงสัย' />
        <Form layout='vertical'>
          {issuesList[issues].options.map((item, index) => {
            return (
              <Form.Item key={index}>
                <Checkbox
                  checked={value[index]}
                  onClick={() =>
                    setValue(
                      value.map((item, idx) => {
                        if (idx === index) {
                          return !item;
                        } else {
                          return item;
                        }
                      })
                    )
                  }
                >
                  {item.name}
                </Checkbox>
                {value[index] && (
                  <TextArea
                    placeholder='อธิบายรายละเอียด'
                    value={detail[index]}
                    onChange={(e) =>
                      setDetail(
                        detail.map((item, idx) => {
                          if (idx === index) {
                            return e.target.value;
                          } else {
                            return item;
                          }
                        })
                      )
                    }
                    autoSize
                  />
                )}
              </Form.Item>
            );
          })}

          {issuesList[issues].options[issuesList[issues].options.length - 1]
            .testkit &&
            value[value.length - 1] && (
              <React.Fragment>
                <Form.Item label='มีการตรวจวิเคราะห์ด้วย (Test kit)'>
                  <Radio.Group
                    value={isTestKit}
                    onChange={(e) => setIsTestKit(e.target.value)}
                  >
                    <Radio value={false}>ไม่มี</Radio>
                    <Radio value={true}>มี</Radio>
                  </Radio.Group>
                </Form.Item>
                {isTestKit && (
                  <React.Fragment>
                    <Form.Item label='ประเภทผลิตภัณฑ์ที่ทดสอบ'>
                      <Select
                        onSelect={(e) => setProductType(e)}
                        value={productType}
                      >
                        {testKitList.map((item, index) => {
                          return (
                            <Option value={item.key} key={index}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item label='ระบุชนิด Test Kit'>
                      <Select onSelect={(e) => setTestKitType(e)}>
                        {testKitList[productType].options.map((item, index) => {
                          return (
                            <Option value={item.key} key={index}>
                              {item.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Radio.Group
                        value={result}
                        onChange={(e) => setResult(e.target.value)}
                      >
                        <Radio value={false}>ผลบวก (Positive)</Radio>
                        <Radio value={true}>ผลลบ (Negative)</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          <Form.Item>
            <Button type='primary' block onClick={onSubmit}>
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
