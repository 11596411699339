import provinces from './data/address/provinces.json';
import districts from './data/address/districts.json';
import subDistricts from './data/address/subDistricts.json';
import zipcodes from './data/address/zipcodes.json';

import { list as locationList } from './data/locationList';

export const FindAddress = (subDistrictCode) => {
  const res = zipcodes.filter(
    (item) => item.SUB_DISTRICT_CODE === subDistrictCode
  );
  let data = {
    province: ProvinceName(res[0]?.PROVINCE_ID),
    district: DistrictName(res[0]?.DISTRICT_ID),
    subDistrict: SubDistrictName(res[0]?.SUB_DISTRICT_ID),
    zipcode: res[0]?.ZIPCODE,
  };
  return data;
};

export const ProvinceName = (id) => {
  return provinces.filter((item) => item.PROVINCE_ID === +id)[0]?.PROVINCE_NAME;
};
export const DistrictName = (id) => {
  return districts.filter((item) => item.DISTRICT_ID === +id)[0]?.DISTRICT_NAME;
};
export const SubDistrictName = (id) => {
  console.log('SubDistrictName', id);
  return subDistricts.filter((item) => item.SUB_DISTRICT_ID === +id)[0]
    ?.SUB_DISTRICT_NAME;
};

export const LocationName = (id) => {
  return locationList.filter((item) => item.key === id)[0].name;
};

export const SubLocationName = (location, id) => {
  return locationList[location].option.filter((item) => item.key === id)[0]
    ?.name;
};
