export const list = [
  { key: 0, name: 'ตลาดนัด', option: [] },
  {
    key: 1,
    name: 'ศาสนสถาน',
    option: [
      { key: 0, name: 'วัด' },
      { key: 1, name: 'โบส' },
      { key: 2, name: 'มัสยิด' },
      { key: 3, name: 'อื่นๆ' },
    ],
  },
  {
    key: 2,
    name: 'ร้านชำ/ร้านขายผลิตภัณฑ์สุขภาพ',
    option: [
      { key: 0, name: 'ร้านขายเครื่องสำอาง' },
      { key: 1, name: 'ร้านขายผลิตภัณฑ์เสริมอาหาร' },
      { key: 2, name: 'ร้านสะดวกซื้อ' },
      { key: 3, name: 'ร้านเสริมสวย' },
      { key: 4, name: 'ร้านนวน/สปา' },
      { key: 5, name: 'ซูปเปอร์มาร์เกต' },
      { key: 6, name: 'ร้านชำ' },
      { key: 7, name: 'อื่นๆ' },
    ],
  },
  { key: 3, name: 'ร้านขายยา', option: [] },
  {
    key: 4,
    name: 'สถานพยาบาลภาคเอกชน',
    option: [
      { key: 0, name: 'โรงพยาบาลเอกชน' },
      { key: 1, name: 'คลินิกแพทย์' },
      { key: 2, name: 'คลินิกพยาบาล' },
      { key: 3, name: 'คลินิกผดุงครรภ์' },
      { key: 4, name: 'อื่นๆ' },
    ],
  },
  {
    key: 5,
    name: 'สถานพยาบาลภาครัฐ',
    option: [
      { key: 0, name: 'โรงพยาบาลรัฐ' },
      { key: 1, name: 'รพ. สต.' },
      { key: 2, name: 'ศูนย์บริการสุขภาพชุมชน' },
      { key: 3, name: 'สถานพยาบาล' },
      { key: 4, name: 'อื่นๆ' },
    ],
  },
  { key: 6, name: 'บ้านผู้ป่วย', option: [] },
  { key: 7, name: 'อื่นๆ', option: [] },
];
