import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import Header from '../../components/Header';
import Policy from '../../components/Policy';
import SucessItem from '../../components/Sucess';

import { Form, Input, Button, Select, message, Checkbox } from 'antd';

import { ProvinceName, DistrictName, SubDistrictName } from '../../tools';

// import { list as AreaList } from '../../data/Areas';
import { list as OfficeList } from '../../data/offices';
import { Register } from '../../services/api';

const { Option } = Select;

const Index = () => {
  const liff = typeof window !== 'undefined' ? window.liff : '';
  const [form] = Form.useForm();

  const [userData, setUserData] = useState();
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [isRegister, setRegistered] = useState(false);
  // const [office, setOffice] = useState('');

  useEffect(() => {
    let userData = JSON.parse(localStorage.getItem('userData')) || null;
    let officer = JSON.parse(localStorage.getItem('officer') || null);
    officer && setFormValue(officer);
    setUserData(userData);
  }, []);

  const setFormValue = (value) => {
    const { office, area, email, lineId, phone, officePhone } = value;
    // setOffice(office);
    form.setFieldsValue({
      office: office,
      area: area,
      email: email,
      lineId: lineId,
      phone: phone,
      officePhone: officePhone,
    });
  };

  const onFinish = async (value) => {
    const {
      userId,
      name,
      surname,
      province,
      district,
      subdistrict,
      zipCode,
      area,
    } = userData;

    let data = {
      userId: userId,
      name: name,
      surname: surname,
      address: {
        province: ProvinceName(province),
        district: DistrictName(district),
        subDistrict: SubDistrictName(subdistrict),
        zipCode: zipCode,
        area: area,
      },
      officer: value,
    };

    const res = await Register(data);
    if (res.status === 200) {
      setRegistered(true);
      localStorage.setItem('officer', JSON.stringify(value));
      localStorage.setItem('isRegister', true);
    } else {
      message.warning(res.message);
    }
  };

  const closeWindow = () => {
    liff.init({ liffId: '1654379233-RmeW7eGW' }, () => {
      liff
        .sendMessages([
          {
            type: 'text',
            text: 'ลงทะเบียนแล้ว',
          },
        ])
        .then(() => {
          liff.closeWindow();
        })
        .catch((err) => {
          console.log('error', err);
        });
    });
  };

  return (
    <Layout>
      <SucessItem visible={isRegister} closeWindow={closeWindow} />
      <Policy visible={visible} onClose={() => setVisible(!visible)} />
      <div className='register'>
        <Header
          current={2}
          title={isRegister ? 'แก้ใขข้อมูลสมาชิก' : 'สมัครสมาชิก'}
          subTitle='ข้อมูลเกี่ยวกับหน่วยงาน'
        />
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
          onFinishFailed={(e) => console.log(e)}
          // onValuesChange={(e) => {
          //   if (e.office) {
          //     setOffice(e.office);
          //   }
          // }}
        >
          <Form.Item
            label='บุคคล/หน่วยงาน'
            name='office'
            rules={[{ required: true }]}
          >
            <Select>
              {OfficeList.map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* {office !== 'บุคคลทั่วไป' && (
            <Form.Item
              label='เขตพื้นที่'
              name='area'
              rules={[{ required: true }]}
            >
              <Select>
                {AreaList.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          )} */}
          <Form.Item
            label='เบอร์มือถือสำหรับประสานงาน'
            name='phone'
            rules={[
              {
                required: true,
                pattern: /^[0]\d{9}$/,
                message: 'กรุณากรอกข้อมูลให้ถุกต้อง',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='เบอร์โทรศัพท์หน่วยงาน'
            name='officePhone'
            rules={[
              {
                pattern: /^[0-9]*$/,
                message: 'กรุณากรอกข้อมูลให้ถุกต้อง',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='Line ID'
            name='lineId'
            // rules={[
            //   {
            //     required: true,
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label='อีเมล์'
            name='email'
            rules={[
              {
                // required: true,
                type: 'email',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className='form-item'>
            <Checkbox
              className='mr-5'
              onChange={() => setDisabled(!disabled)}
            ></Checkbox>
            ยอมรับ
            <span className='terms' onClick={() => setVisible(!visible)}>
              เงื่อนไข
            </span>
            การใช้บริการ
          </div>
          <Form.Item>
            <Button type='primary' block htmlType='submit' disabled={disabled}>
              ยืนยัน
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
};

export default Index;
