export const list = [
  'สำนักงานสาธารณสุขจังหวัด',
  'สำนักงานสาธารณสุขอำเภอ',
  'สถานีอนามัย',
  'สถานบริการสาธารณสุขชุมชน',
  'โรงพยาบาลศูนย์',
  'โรงพยาบาลทั่วไป',
  'โรงพยาบาลชุมชน',
  'ศูนย์สุขภาพชุมชน ของ รพ.',
  'ศูนย์วิชาการ',
  'โรงพยาบาล นอก สป.สธ.',
  'โรงพยาบาล นอก สธ.',
  'ศูนย์บริการสาธารณสุข',
  'โรงพยาบาลเอกชน',
  'คลินิกเอกชน',
  'โรงพยาบาล/ศูนย์บริการสาธารณสุข สาขา',
  'โรงพยาบาลส่งเสริมสุขภาพตำบล',
  'องค์กรปกครองส่วนท้องถิ่นรูปแบบพิเศษเมือง',
  'องค์การบริหารส่วนจังหวัด',
  'องค์การบริหารส่วนตำบล',
  'เทศบาลตำบล',
  'เทศบาลเมือง',
  'บุคคลทั่วไป',
  'อย.น้อย',
  'อื่นๆ',
];
