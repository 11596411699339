export const list = [
  {
    key: 0,
    name: 'ยาแผนปัจจุบัน',
    options: [
      { key: 0, name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ', testkit: false },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: false,
      },
    ],
  },
  {
    key: 1,
    name: 'ยาแผนโบราณ/ยาที่พัฒนาจากสมุนไพร',
    options: [
      { key: 0, name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ', testkit: false },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: true,
      },
    ],
  },
  {
    key: 2,
    name: 'สมุนไพร',
    options: [
      {
        key: 0,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 1,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: false,
      },
    ],
  },
  {
    key: 3,
    name: 'วัตถุออกฤทธิ์/วัตถุเสพติด',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: false,
      },
    ],
  },
  {
    key: 4,
    name: 'ยาชุด',
    options: [
      {
        key: 0,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 1,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: true,
      },
    ],
  },
  {
    key: 5,
    name: 'เครื่องมือแพทย์',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: false,
      },
    ],
  },
  {
    key: 6,
    name: 'เครื่องสำอาง',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 1,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: true,
      },
    ],
  },
  {
    key: 7,
    name: 'ผลิตภัณฑ์เสริมอาหาร',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: true,
      },
    ],
  },
  {
    key: 8,
    name: 'อาหาร',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: true,
      },
    ],
  },
  {
    key: 9,
    name: 'วัตถุอันตราย/สารเคมี',
    options: [
      {
        key: 0,
        name: 'ผลิตภัณฑ์ที่เสื่อมคุณภาพ',
        testkit: false,
      },
      {
        key: 1,
        name: 'ไม่บอกความจริงหรือโฆษณาโอ้อวดสรรพคุณเกินจริง',
        testkit: false,
      },
      {
        key: 2,
        name: 'ข้อสงสัยอื่นๆ',
        testkit: false,
      },
    ],
  },
];
