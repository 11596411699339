export const list = [
  {
    key: 0,
    name: "ยา",
    options: [
      { key: 0, name: "ชุดทดสอบสเตียรอยด์" },
      { key: 1, name: "ชุดทดสอบไซบูทรามีน" },
    ],
  },
  {
    key: 1,
    name: "เครื่องสำอาง",
    options: [
      { key: 0, name: "ชุดทดสอบไฮโดรควิโนน" },
      { key: 1, name: "ชุดทดสอบกรดเรติโนอิก (กรดวิตามินเอ)" },
      { key: 2, name: "ชุดทดสอบปรอท" },
      { key: 3, name: "ชุดทดสอบสเตียรอยด์ในเครื่องสำอาง" },
    ],
  },
  {
    key: 2,
    name: "อาหาร",
    options: [
      { key: 0, name: "ชุดทดสอบบอแรกซ์ (ผงกรอบ) ในอาหารและสารเคมี" },
      { key: 1, name: "ชุดทดสอบยาฆ่าแมลง (กลุ่มฟอสเฟต, คาร์บาเมต)" },
      { key: 2, name: "ชุดทดสอบฟอร์มาลิน (น้ำยาดองศพ)" },
      { key: 3, name: "ชุดทดสอบโซเดียมไฮโดรซัลไฟต์ (สารฟอกขาว)" },
      { key: 4, name: "ชุดทดสอบโคลิฟอร์ม" },
      { key: 5, name: "ชุดทดสอบกรดซาลิซิลิค (สารกันรา)" },
      { key: 6, name: "ชุดทดสอบปริมาณไอโอดีนในเกลือบริโภค" },
      { key: 7, name: "ชุดทดสอบน้ำมันทอดซ้ำ" },
    ],
  },
];
