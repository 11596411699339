import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import Register1 from './views/Register/1';
import Register2 from './views/Register/2';
import LocationReport from './views/LocationReport';
import LocationSymptom from './views/LocationSymptom';
import CurrentLocation from './views/CurrentLocation';
import IssuesReport from './views/IssuesReport';
import ProductModel from './views/ProductModel';

import './App.css';
import './assets/scss/style.scss';
import 'antd/dist/antd.less';

function App() {
  return (
    <ConfigProvider componentSize='large'>
      <Router forceRefresh>
        <Switch>
          <Route
            exact
            path='/register-1'
            name='register-1'
            component={Register1}
          />
          <Route
            exact
            path='/register-2'
            name='register-2'
            component={Register2}
          />
          <Route
            exact
            path='/location-report'
            name='Location Report'
            component={LocationReport}
          />
          <Route
            exact
            path='/location-symptom'
            name='Location Symptom'
            component={LocationSymptom}
          />
          <Route
            exact
            path='/current-location'
            name='Location Report'
            component={CurrentLocation}
          />
          <Route
            exact
            path='/issues-report'
            name='Issues Report'
            component={IssuesReport}
          />
          <Route
            exact
            path='/product-model'
            name='Product Model'
            component={ProductModel}
          />
        </Switch>
      </Router>
    </ConfigProvider>
  );
}

export default App;
