import React, { useEffect } from 'react';
import { Typography } from 'antd';

const Index = ({ current, title, subTitle }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div className='header'>
      <div className='title'>
        <Typography.Title level={4}>{title}</Typography.Title>
      </div>
      {subTitle && (
        <div className='sub-title'>
          <div>
            <Typography.Title level={5}>{subTitle}</Typography.Title>
          </div>
          {current && (
            <div className='step'>
              <div className={'item active'}>1</div>
              <hr className={current === 2 ? 'active' : ''} />
              <div className={current === 2 ? 'item active' : 'item'}>2</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Index;
